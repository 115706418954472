<template>
  <div class="observer"/>
</template>
<script>
export default {
  mounted() {
    this.observer = new IntersectionObserver(([entry]) => {
      if (entry && entry.isIntersecting) {
        /**
         * If the observer is intersecting with the viewport.
         * @event intersect
         */
        this.$emit('intersect');
      }
    });

    this.observer.observe(this.$el);
  },
};
</script>
