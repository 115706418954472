<template>
    <div class="entity-info">
      <div
        :data-cy="imgDataCy"
        :style="previewStyle"
        :class="{ 'entity-info__preview': true, 'has-border': imgHasBorder }"
        @click="onPreviewClick"
        v-tooltip="imgTooltip"></div>
      <div v-if="hasInfo" class="entity-info__text">
        <slot></slot>
      </div>
    </div>
</template>

<script>
/**
 * Generic component for encapsulating entity info.
 * Able to render entity image as `div` with background.
 * Also, its default slot is intended for rendering additional entity info (eg: name, status...)
 *
 * @version 1.0.0
 */
export default {
  props: {
    /**
     * Entity image width
     */
    imgWidth: {
      type: String,
      required: false,
      default: '32px',
    },
    /**
     * Entity image height
     */
    imgHeight: {
      type: String,
      required: false,
      default: '32px',
    },
    /**
     * Entity image url
     */
    imgUrl: {
      type: String,
      required: false,
      default: '',
    },
    /**
     * Entity image url
     */
    imgHasBorder: {
      type: Boolean,
      required: false,
      default: true,
    },
    /**
     * Entity image border radius
     */
    imgBorderRadius: {
      type: String,
      required: false,
      default: '.25rem',
    },
    imgTooltip: {
      type: Object,
      required: false,
    },
    imgDataCy: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    hasInfo() {
      return !!this.$slots.default;
    },
    previewStyle() {
      return {
        backgroundImage: this.imgUrl ? `url('${this.imgUrl}')` : null,
        height: this.imgHeight,
        width: this.imgWidth,
        borderRadius: this.imgBorderRadius,
      };
    },
  },
  methods: {
    onPreviewClick() {
      /**
       * When preview image is clicked
       * @event previewClick
       */
      this.$emit('previewClick');
    },
  },
};
</script>

<style scoped lang="scss">
  $preview-bg-color: #F1F4F7;
  $preview-border-color: #DEE1E4;
  $text-color: #252631;

  .entity-info {
    display: flex;
    align-items: stretch;
    max-width: 100%;
    &__preview {
      background-color: $preview-bg-color;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      flex-shrink: 0;

      &.has-border {
        border: 1px solid $preview-border-color;
      }
    }
    &__text {
      @extend %flex-center;
      margin-left: 8px;
      color: $text-color;
      width: 100%;
      overflow-x: hidden;
      white-space: nowrap;

      small {
        color: #6C7995;
        font-weight: 600;
      }
    }

    &__suffix { line-height: 0; }
  }
</style>
