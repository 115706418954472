<template>
  <ez-observer @intersect="$emit('loadMore')"/>
</template>

<script>
import EzObserver from '@/components/ui/Observer';

export default {
  components: {
    EzObserver,
  },
};
</script>
