<script>
/**
 * Label
 * @version 1.0.0
 * @since
 */
import mergeData from 'vue-merge-data';

export default {
  functional: true,
  props: {
    as: {
      type: String,
      required: false,
      default: 'label',
    },
  },
  render(h, context) {
    const { as } = context.props;
    const data = {
      class: 'label',
    };
    return h(as, mergeData(context.data, data), context.slots().default);
  },
};
</script>
<style lang="scss" scoped>
.label {
  display: block;
  @include font-size(12px);
  font-weight: 500;
  color: $color-gray-6C;
}
</style>
