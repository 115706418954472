<script>
/**
 * Well this whole component will look like it was written in React...
 * For understanding how this is made a really in-depth knowledge of VueJS render function `h()`
 * is required.
 * @see https://vuejs.org/v2/guide/render-function.html#createElement-Arguments
 */
import { titleCase } from 'change-case';
import EzLoader from '@/components/ui/Loader/EzLoader';

const defaultHeaderFn = (h, { key }) => titleCase(key);

const getChildrenTextContent = children =>
  (children || [])
    .map(node => (node.children ? getChildrenTextContent(node.children) : node.text))
    .join('');
/**
 * When a component is marked a `functional`, it means that it is `stateless` and `instanceless`.
 * Meaning there is no `this`, everything is passed through `context`
 * @type {{functional: boolean, render(*, *): *}}
 * @see https://vuejs.org/v2/guide/render-function.html#Functional-Components
 */
const THead = {
  functional: true,
  render(h, context) {
    const {
      props: { headers, columns, columnProps, headerProps },
    } = context;
    return h('thead', null, [
      h(
        'tr',
        null,
        columns.map(key => {
          // const renderFun = headers[key] || defaultHeaderFn;
          const cellProps = columnProps ? columnProps[key] : null;
          const headerCellProps = headerProps ? headerProps[key] : null;

          const headerContentFn = headers[key] !== undefined ? headers[key] : defaultHeaderFn;
          return h('th', { ...cellProps, ...headerCellProps }, [
            headerContentFn(h, { key, ...context }),
          ]);
        }),
      ),
    ]);
  },
};

const TBody = {
  functional: true,
  render(h, context) {
    const {
      props: { data, headers, columns, columnProps, rowCls, rowDataCy, removeButton },
      listeners: { rowClick, removeItem, rowHover },
      data: { scopedSlots = {} },
    } = context;

    return h(
      'tbody',
      null,
      data.map(row => {
        let rowClass = null;
        if (typeof rowCls === 'string') {
          rowClass = rowCls;
        } else if (typeof rowCls === 'function') {
          rowClass = rowCls(row);
        }

        let rowCy = null;
        if (typeof rowDataCy === 'string') {
          rowCy = rowDataCy;
        } else if (typeof rowDataCy === 'function') {
          rowCy = rowDataCy(row);
        }

        const removeEl = removeButton
          ? h(
              'button',
              {
                on: {
                  click: event => {
                    event.stopPropagation();
                    if (typeof removeItem === 'function') {
                      removeItem(row, event);
                    }
                  },
                },
                type: 'button',
                class: 'button button--remove-button remove-button',
              },
              [
                h('font-awesome-icon', {
                  props: { icon: 'trash' },
                }),
              ],
            )
          : null;

        return h(
          'tr',
          {
            on: {
              ...(rowClick !== undefined
                ? {
                    click: event => {
                      event.stopPropagation();
                      rowClick(row, event);
                    },
                    keypress: event => {
                      if (event.key === 'Enter' && event.srcElement.nodeName === 'TR') {
                        rowClick(row, event);
                      }
                    },
                  }
                : {}),
              ...(rowHover !== undefined
                ? {
                    mouseover: event => {
                      rowHover(row, event);
                    },
                    mouseleave: event => {
                      rowHover(null, event);
                    },
                  }
                : {}),
            },
            class: rowClass,
            attrs: {
              tabindex: 0,
              ...(rowCy ? { 'data-cy': rowCy } : {}),
            },
          },
          [
            columns.map((key, ind) => {
              const cellRenderFn = scopedSlots[`cell-${key}`];
              const cellProps = columnProps ? columnProps[key] : null;
              const isLastCell = ind === columns.length - 1;

              const headerContentFn = headers[key] !== undefined ? headers[key] : defaultHeaderFn;

              const cellLabel = headerContentFn(h, { key, ...context });

              const labelText =
                typeof cellLabel === 'string'
                  ? cellLabel
                  : getChildrenTextContent(cellLabel.children);

              if (cellRenderFn !== undefined) {
                return h(
                  'td',
                  {
                    ...cellProps,
                    attrs: { 'data-cell-label': labelText },
                  },
                  [cellRenderFn({ cell: row[key], row, key }), isLastCell ? removeEl : null],
                );
              }

              return h(
                'td',
                {
                  ...cellProps,
                  attrs: { 'data-cell-label': labelText },
                },
                [row[key], isLastCell ? removeEl : null],
              );
            }),
          ],
        );
      }),
    );
  },
};

const Table = {
  functional: true,
  render(h, context) {
    const {
      props,
      data: { staticClass: classes },
    } = context;
    const headers = props.headers === false ? null : h(THead, { ...context.data, props });
    const body = h(TBody, { ...context.data, props });

    return h(
      'table',
      {
        class: {
          table: true,
          'table--disable-hover': props.disableHover,
          'table--remove-button': props.removeButton,
          [classes]: true,
        },
      },
      [headers, body],
    );
  },
};

/**
 * Reusable custom table component with custom prop rendering cells.
 */
/**
 * When a row is clicked inside a table. It will return two parameters `row, event`.
 *
 * @event rowClick
 * @type {Object} row
 * @type {Event} event
 */

export default {
  functional: true,
  props: {
    /**
     * Array of objects used to show the data. Keys from the object will be used to
     * identify cells.
     */
    data: {
      required: true,
      type: Array,
      validator: data => data.every(row => typeof row === 'object'),
    },
    /**
     * Array of keys that will be shown in the header. **Order is important here**.
     * All the keys in this array will be shown, other keys will be ignored from `data`.
     */
    columns: {
      required: true,
      type: Array,
      validator: columnOrder => columnOrder.every(cell => typeof cell === 'string'),
    },
    /**
     * Properties passed to the table td elements
     */
    columnProps: {
      required: false,
      type: Object,
      default: null,
    },
    /**
     * Properties passed to the table th elements
     */
    headerProps: {
      required: false,
      type: Object,
      default: null,
    },
    /**
     * Object of functions. Used to override the default rendering function of a `th` cell.
     * Key of the object is the `key` used in `data`. Function can use the render function `h` to
     * render DOM elements.
     * example: `(h) => h('em', null, 'Custom italic header')`
     */
    headers: {
      required: false,
      type: [Object, Boolean],
      validator: headers => Object.keys(headers).every(key => typeof headers[key] === 'function'),
      default: () => ({}),
    },
    /**
     * String or function returning string, which allows custom class to be added to the table row.
     * example: `(row) => row.status === 'accepted' ? 'class-accepted' : null`
     */
    rowCls: {
      type: [String, Function],
      required: false,
      default: null,
    },
    /**
     * String or function returning string, which allows custom 'data-cy' to be added to the table row.
     */
    rowDataCy: {
      type: [String, Function],
      required: false,
      default: null,
    },
    /**
     * Controls if the table rows should change style when hovered
     */
    disableHover: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Controls if the table rows should render delete button when hovered
     */
    removeButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Controls if the table show loading state
     */
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasMinHeight: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  render(h, context) {
    const { props } = context;
    const { hasMinHeight } = props;
    const table = h(Table, { ...context.data, props });
    const loader = h(EzLoader, { props: { show: props.loading } }, ['Loading...']);

    return h(
      'div',
      {
        style: {
          position: 'relative',
          minHeight: hasMinHeight ? '100px' : 'initial',
        },
      },
      [table, loader],
    );
  },
};
</script>

<style lang="scss" scoped>
$border-color: #e9ebf2;
$background-color: #ffffff;

$row-hover-bg: #fcfcfc;
$row-hover-border: #dfe2eb;

$cell-padding: 0.75rem 1rem;
$header-cell-padding: 0.5rem 1rem;

$edge-padding: 1.5em;

@mixin cell-types() {
  &.extra-small-cell {
    width: 40px;
  }
  &.small-cell {
    width: 80px;
  }
  &.medium-cell {
    width: 125px;
  }
  &.large-cell {
    width: 170px;
  }
  &.extra-large-cell {
    width: 215px;
  }
  &.xxl-cell {
    width: 270px;
  }
  &.width-100-cell {
    width: 100%;
  }
  &.text-right-cell {
    text-align: right;
  }
  &.date-cell {
    width: 130px;
  }
  &.status-cell {
    width: 180px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  &.status-small-cell {
    width: 144px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  &.price-cell {
    width: 120px;
    text-align: right;
    padding-left: 0;
  }
  &.price-small-cell {
    width: 80px;
    text-align: right;
    padding-left: 0;
  }
  &.sku-cell {
    text-transform: uppercase;
  }
  &.qty-cell {
    width: 50px;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  &.no-padding-cell {
    padding: 0;
  }
  &.no-side-padding-cell {
    padding-right: 0;
    padding-left: 0;
  }
  &.select-cell {
    width: 130px;
  }
}
.table {
  @include font-size(14px);
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  background-color: $background-color;
  table-layout: fixed;

  :deep() th,
  :deep() td {
    @include cell-types();
  }

  :deep() thead th {
    @include font-size(12px);
    font-weight: 500;
    border-bottom: 1px solid $border-color;
    color: $color-gray-6C;
    text-transform: capitalize;
    text-align: left;
    white-space: nowrap;
    padding: $header-cell-padding;
    overflow-x: hidden;
    text-overflow: ellipsis;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      text-align: right;
      padding-right: 0;
    }
    &:last-child:first-child {
      text-align: left;
    }
  }

  :deep() tbody {
    tr {
      outline: none;
      transition: 0.3s ease-in-out;
      &:hover {
        cursor: pointer;
        td {
          background-color: #f5f6fa;
        }
      }
      td {
        color: $color-gray-6C;
        font-weight: 400;
        border-bottom: 1px solid $border-color;
        padding: $cell-padding;
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          text-align: right;
          padding-right: 0;
        }
        &:last-child:first-child {
          text-align: left;
        }
        &.price-cell {
          @include font-size(16px);
          font-weight: bold;
          color: $color-gray-25;
        }
      }
    }
  }

  &--disable-hover {
    :deep() tbody {
      tr:hover {
        box-shadow: none;
        cursor: default;

        td {
          background-color: transparent;
        }
      }
    }
  }
  &--remove-button {
    :deep() tbody {
      .button {
        svg {
          margin-right: 0;
        }
        @include button-type(
          'remove-button',
          $button-secondary-bg,
          $button-secondary-color,
          $button-secondary-hover-bg,
          $button-secondary-bg
        ) {
          margin-left: $spacing-08;
          width: 2rem;
          height: 2rem;
          &:hover {
            cursor: pointer;
          }
        }
      }
      .remove-button {
        display: none;
        padding: 0;
      }
      tr {
        td {
          @include font-size(14px, 32px);
          position: relative;
        }
        &:hover {
          td:last-child {
            color: rgba(255, 255, 255, 0);
            font-size: 0;
            line-height: 0;
          }
          .remove-button {
            display: inline-block;
          }
        }
      }
    }
  }
}

@media (max-width: 1023px) {
  .table {
    :deep() thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    :deep() tbody {
      tr {
        border-bottom: 1px solid $border-color;
        display: block;
        + tr {
          padding: 1rem 0;
        }
        &:nth-child(odd) {
          background: #fcfcfc;
        }
        td {
          border-bottom: 0;
          display: flex !important;
          align-items: center;
          text-align: left !important;

          padding-right: 0.5rem !important;
          padding-left: 0.5rem !important;

          width: 100% !important;

          &:before {
            min-width: 50%;
            content: attr(data-cell-label);
            font-weight: bold;
            text-transform: uppercase;
          }

          &.price-cell {
            @include font-size(14px);
            &:before {
              color: $color-gray-6C;
              text-align: left;
            }
          }
        }
      }
    }
  }
}
</style>
